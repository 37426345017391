/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import msgToast from '@/components/Toast/msgToast';
import XInput from 'vux/src/components/x-input';
export default {
  name: 'Recharge',
  components: {
    XInput: XInput,
    msgToast: msgToast
  },
  data: function data() {
    return {
      //充值金额
      rechargeValue: '',
      show: true,
      okbtn: true,
      //可不可以再点
      toast: false,
      //弹窗
      msgObj: {
        msg: '--',
        mflag: false
      },
      //弹窗传值
      Confirm: false,
      //确认按钮
      set: {},
      blur: false
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return !(this.rechargeValue !== '' && this.rechargeValue > 0 && this.isDuringDate() && (this.set.refundAmt == 0 || +this.rechargeValue <= +this.set.refundAmt) && this.okbtn && +this.rechargeValue <= +this.set.acAddo);
    }
  },
  watch: {
    rechargeValue: function rechargeValue() {}
  },
  methods: {
    changeInput: function changeInput(val) {
      var _this = this;

      if (val == '' || val == 0) {
        return;
      }

      var reg = /(^[1-9][0-9]{0,4}$)|(^[0][.]{1}[1-9]{1}$)|(^[0][.]{1}[0-9]{1}[1-9]{1}$)|(^[1-9][0-9]{0,4}[.]{1}[0-9]{0,2}$)/;

      if (!reg.test(Number(val))) {
        this.$refs.input01.blur();
        this.msgObj = {
          msg: "\u8BF7\u8F93\u5165\u6B63\u786E\u7684\u91D1\u989D \uFF01",
          mflag: false
        };
        this.toast = true;
        setTimeout(function () {
          _this.rechargeValue = Number(val).toFixed(2);
        }, 400);
      } else if (this.set.refundAmt > 0 && +val > +this.set.refundAmt) {
        this.$refs.input01.blur();
        this.rechargeValue = this.set.refundAmt;
        this.msgObj = {
          msg: "\u6700\u5927\u53EF\u9000\u6B3E\u91D1\u989D\u4E3A\uFF1A".concat(this.set.refundAmt, "\u5143"),
          mflag: false
        };
        this.toast = true;
      } else if (+val > +this.set.acAddo) {
        this.$refs.input01.blur();
        this.rechargeValue = this.set.acAddo;
        this.msgObj = {
          msg: "\u8D26\u6237\u4F59\u989D\u4E3A\uFF1A".concat(this.set.acAddo, "\u5143,\u9000\u6B3E\u91D1\u989D\u4E0D\u53EF\u5927\u4E8E\u8D26\u6237\u4F59\u989D"),
          mflag: false
        };
        this.toast = true;
      }
    },

    /**
     * [isDuringDate 是否在指定的时间内
     * @author lwt
     * @DateTime 2020-11-18
     * @version  1.0
     * @param    date   [beginDateStr] [开始时间]
     * @param    date   [endDateStr]   [结束时间]
     * @return   Boolean
     */
    isDuringDate: function isDuringDate() {
      if (!this.set.refundstart) {
        return true;
      } else if (this.set.refundstart == this.set.refundend) {
        return true;
      }

      var curDate = this.$utils.DateUtils.getTimeStamp(new Date());
      var beginDateStr = this.$utils.DateUtils.getYYYYMMDD(new Date()) + ' ' + this.set.refundstart;
      var endDateStr = this.$utils.DateUtils.getYYYYMMDD(new Date()) + ' ' + this.set.refundend;
      var beginDate = this.$utils.DateUtils.getTimeStamp(beginDateStr);
      var endDate = this.$utils.DateUtils.getTimeStamp(endDateStr);

      if (curDate >= beginDate && curDate <= endDate) {
        return true;
      }

      return false;
    },
    onConfirm: function onConfirm() {},
    onclose: function onclose() {
      this.toast = false; // this.msgObj.mflag = false

      this.okbtn = true;

      if (this.msgObj.mflag == true) {
        this.$router.push('/Account');
      }
    },
    refundMoney: function refundMoney() {
      var _this2 = this;

      if (!this.okbtn) return;
      this.Confirm = false;
      this.okbtn = false;
      var money = Math.floor(parseFloat((this.rechargeValue || 0) * 10000, 10).toFixed(2) / 100);
      this.$api.Spend.refund({
        userSerial: this.$utils.Store.getItem('userSerial'),
        refundMoney: money
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          if (code == 604) {
            _this2.msgObj = {
              msg: msg
            };
          } else if (code == 605) {
            _this2.msgObj = {
              msg: msg
            };
          } else {
            _this2.msgObj = {
              msg: msg // en:'The user has not made a WeChat recharge and cannot get a refund.'

            };
          }

          _this2.msgObj.mflag = false;
          _this2.toast = true;
        } else {
          _this2.toast = true;
          _this2.msgObj = {
            msg: Number(data.refundFee) / 100,
            mflag: true
          };
          _this2.rechargeValue = '';
        }
      }).catch(function () {
        _this2.okbtn = true;

        _this2.$utils.Tools.httpError();
      });
    } // get() {
    //  var resp= JSON.parse(this.$utils.Store.getItem('resp'))
    //   var Location = {
    //       latitude: resp.latitude,
    //       longitude: resp.longitude,
    //       address: resp.address,
    //       name: resp.name,
    //       scale: 1, // 地图缩放级别,整型值,范围从1~28。默认为最大
    //       infoUrl: '', // 在查看位置界面底部显示的超链接,可点击跳转
    //   }
    //   wx.openLocation(Location)
    // },

  },
  created: function created() {
    this.set = JSON.parse(this.$utils.Store.getItem('set'));
    var numMoeny = Number(this.set.acAddo);

    if (Number(this.set.acAddo) > Number(this.set.refundAmt)) {
      numMoeny = Number(this.set.refundAmt);
    }

    if (numMoeny == 0) {
      numMoeny = Number(this.set.acAddo);
    }

    this.rechargeValue = numMoeny.toFixed(2);
  },
  mounted: function mounted() {}
};