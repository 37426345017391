/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      eshow: false,
      info: {
        msg: '-',
        en: '-',
        mflag: false
      }
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    msgObj: {
      type: Object,
      defalut: {}
    }
  },
  watch: {
    show: function show(val) {
      this.eshow = val;
    },
    msgObj: function msgObj(newVal) {
      this.info = newVal;
    }
  },
  methods: {
    close: function close() {
      this.eshow = !this.eshow;
      this.$emit("onclose");
    }
  }
};